<template>
  <div>
    <Banner/>
    <NavBar>
      <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          <router-link to="/news">新闻中心</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          {{newsInfo.newsData?.newsTitle}}
        </el-breadcrumb-item>
    </NavBar>
    <div id="content">
      <div id="contentTitle">&nbsp;{{ newsInfo.newsData?.newsTitle }}</div>
      <div id="contentInfo">
        作者：深圳市晶准通信技术有限公司&nbsp;&nbsp;
        浏览：{{ newsInfo.newsData?.pageView }}&nbsp;&nbsp;
        发布时间：{{ newsInfo.newsData?.releaseTime }}&nbsp;&nbsp;
      </div>
      <div id="detailTitle">晶准通信————</div>
      <br/>
      <div id="detailContent" v-html="newsInfo.newsData?.newsContent">
      </div>
      <hr/>
    </div>
  </div>
</template>

<script>
import Banner from '../components/common/Banner'
import NavBar from '../components/common/NavBar'
export default {
  name:"newsDetail",
  props:['id'],
  components:{
    Banner,
    NavBar
  },
  data(){
    return{
      newsInfo:{
        newsData:{}
      }
    }
  },
  created(){
    this.$axios.get(`/read/getNewsDetail?id=${this.id}`).then((res)=>{
      const result = res[0]
      result.newsContent = result.newsContent.replaceAll('/n','<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
      this.$set(this.newsInfo,'newsData',result)
    })
  },
}
</script>

<style scoped>
  .el-breadcrumb__inner a{
    font-weight: normal;
    font-size: 12px;
    color: #333;
  }
  .el-breadcrumb__inner:hover a{
    color: #333;
  }
  #content{
    max-width: 1200px;
    margin: auto;
    padding: 40px 0;
    font-family: '"Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif';
  }
  #contentTitle{
    border-left:4px solid #333 ;
  }
  #contentInfo{
    font-size: 12px;
    color: #838383;
    line-height: 1.875rem;
    font-family: 'Microsoft YaHei,SimSun,Arial';
    border-bottom: 1px solid #eee;
  }
  #detailTitle{
    font-weight: 600;
    font-size: 20px;
  }
  #detailContent{
    text-indent: 2em;
    font-size: 16px;
    line-height: 1.5rem;
    padding-bottom: 40px;
  }
</style>